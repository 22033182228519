import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../../components/structure';
import { Content, Hero } from "../../../components/blocks"
import styled, { css } from "styled-components"
import { brand, font, mq } from "../../../styles";
import GatsbyImage from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";


const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;
    
    @media only screen and ${mq.maxMd} {
      text-align: center;
      font-size: 42px;
    }

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

}
`;

const Copy = styled.div`
    margin-top: 50px;
    margin-bottom: 20px;
  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 22px;
    a {
      display: inline-block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  } 
`;

const PrivacyPolicy = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: auto;
`
const PrivacyPage = () => {


  return(
    <Layout>
    <SEO title="Règles de Confidentialité | Plusgrade" 
          description={'Plusgrade - Règles de Confidentialité'}
      />
      <Content whiteBG noMargin>
        <PrivacyPolicy>
          <Header>
              <h2>Plusgrade<span className={"color-emphasis"}> politique de divulgation de vulnérabilités</span></h2>
          </Header>
          <Copy>
            <p>
            Chez Plusgrade, nous prenons la cybersécurité très au sérieux, cependant nous réalisons qu'aucune technologie n'est parfaite.  Nous nous engageons à protéger les données que nous recevons de nos partenaires et les données personnelles pertinentes, c'est pourquoi nous proposons un programme de divulgation des vulnérabilités.  Nous croyons que travailler avec des chercheurs en cybersécurité à travers le monde est crucial pour identifier les faiblesses de la technologie.  Nous nous soucions de la sécurité des informations, et nous sommes prêts à travailler avec des chercheurs en cybersécurité pour améliorer nos services. Plusgrade estime que la recherche sur la cybersécurité effectuée de bonne foi fournit un service public inestimable.
            </p>
            <p>
            Nous sommes ravis que vous participiez en tant que chercheur en cybersécurité pour nous aider à identifier les vulnérabilités de nos applications Web.</p>
            <p>Plusgrade s'est associé à Bugcrowd pour gérer le programme de divulgation des vulnérabilités.  Les vulnérabilités soumises au programme seront triées, évaluées et classées en fonction des <a href="https://bugcrowd.com/vulnerability-rating-taxonomy">Taxonomie d'évaluation de la vulnérabilité de Bugcrowd</a>. Les chercheurs doivent également suivre les conditions de <a href="https://www.bugcrowd.com/resources/essentials/standard-disclosure-terms/">divulgation standard de Bugcrowd</a>.</p>
            <p>Le programme de Plusgrade ne permet pas la divulgation publique et les chercheurs ne peuvent pas divulguer au public des informations sur les vulnérabilités trouvées dans ce programme.
</p>
<p>Le programme de vulnérabilité Plusgrade est accessible sur Bugcrowd en suivant le lien: <a href="http://bugcrowd.com/plusgrade-vdp-pro">bugcrowd.com/plusgrade-vdp-pro</a>  (Veuillez noter que la plateforme Bugcrowd n’est offerte qu’en anglais.)</p>
<p>Bonne chance et bonne chasse!</p>
          </Copy>
        </PrivacyPolicy>
      </Content>
  </Layout>
  );
  
}

export default PrivacyPage;

const QuestionSectionContainer = styled.div`
font-family: ${font.family.sherika};
pointer-events: none;
p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 10px;
  font-size: 24px;
  font-weight: 700;
  color: ${brand.colors.pg_blue};
  margin-bottom: 10px;
  pointer-events: all;
  cursor: pointer;
  border-bottom: 1px solid #BDC4D1;
  padding-bottom: 10px;

  .expand-icon {
    color: #BDC4D1;
    font-size: 40px;
  }
}

.reveal-container {
  max-height: 0;
  height: auto;
  overflow: hidden;
  border: none;
  width: 95%;
  margin: 0 auto 20px;
   p {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: ${brand.colors.pg_primary_dark};
    margin: 10px 0;
  }

  .italized {
    font-style: italic;
    font-size: 20px;
  }

  a {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
    pointer-events: all;
  }

  ol {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_blue};
      list-style: none;
      font-weight: 800;
      margin: 10px 0;
      font-size: 20px;

      ul > li {
        color: ${brand.colors.pg_primary_dark};
        font-weight: 400;
        font-size: 20px;
      }

      ul > li:before {
        content: '';
      }
    }

    li:before {
      content: counter(list, lower-alpha) ") ";
      counter-increment: list;
      font-size: 20px;
    }
  }
  ul {
    margin-left: 1.75rem;
    margin-bottom: 20px;
    li {
      color: ${brand.colors.pg_primary_dark};
      list-style: circle;
    }

    ul > li {
      list-style: square;
    }
  }
}

${props => props.open === true && css`
  p {
    border: none;
  }
  .reveal-container {
    max-height: 6000px;
  }
`}

`;

const LearnMoreSection = styled.div`
  .learn-more-clickable {
    color: ${brand.colors.pg_blue} !important;
    text-decoration: underline;
  }
  .learn-more-expandable {
    color: ${brand.colors.pg_primary_dark};
    height: auto;
    max-height: 0;
    width: 95%;
    margin: auto;

    a {
      display: block;
      pointer-events: all;
      cursor: pointer;
      text-decoration: underline;
      color: ${brand.colors.pg_blue};
    }
  }

  ${props => props.open === true && css`
    .learn-more-expandable {
      max-height: 6000px;
    }
  `}
`;



{/**
Question Section Component
Accepts
- Question prop = Clickable Header section question
- Answer Prop = Content of dropdown, accepts HTML. Check styled component here carefully as adding p, ul, or ol are pre-styled.
- learnMoreContent = Dropdown content of the 'learn more' section
*/}
const QuestionSection = ({question, answer, learnMoreContent}) => {

const [FAQOpen, setFAQSection] = useState(false);
const [learnMoreOpen, setLearnMore] = useState(false); 

  const pageQuery = useStaticQuery(graphql`
  query{
    plusIconGrey: file(base: {eq: "plus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

    minusIconGrey: file(base: {eq: "minus-grey.png"}){
      childImageSharp{
        fixed(width: 32, height: 32, webpQuality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }

  }
`);

let plusIcon = <span className={"expand-icon"}>+</span>
let minusIcon = <span className={"expand-icon"}>-</span>
const openClose = () => {
  if (FAQOpen && learnMoreOpen){
    setLearnMore(false);
  }
  setFAQSection(!FAQOpen); 
}

const expandCompressLearnMore = () => {
  setLearnMore(!learnMoreOpen);
}
  return(
    <QuestionSectionContainer open={FAQOpen}>
      <p onClick={openClose}>{question} {FAQOpen ? minusIcon : plusIcon}</p>
      <div className={"reveal-container"}>
        {answer}
      {
        learnMoreContent ? 
        <LearnMoreSection open = {learnMoreOpen}>
          <p className={"learn-more-clickable"} onClick={expandCompressLearnMore}>Apprendre Plus</p>
          <div className={"learn-more-expandable"}>
            {learnMoreContent}
          </div>
      </LearnMoreSection>
      :
      <></>
      }
      </div>
    </QuestionSectionContainer>
  );
}
